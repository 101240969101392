$separator-color-light: hsl(0, 0%, 95%);
$separator-color: hsl(0, 0%, 84%);
$background-color: hsl(0, 0%, 97%);
$foreground-color: hsl(0, 0%, 100%);
$input-background: hsl(0, 0%, 100%);

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&family=Poppins&family=Source+Sans+Pro&display=swap");

//fonts-variables

$font-poppins: "Poppins", sans-serif;
$font-nunito: "Nunito", sans-serif;
$font-source-sans-pro: "Source Sans Pro", sans-serif;

$dark-btn-background: hsl(300, 6%, 7%);
$light-btn-background: hsl(0, 0%, 93%);

$button-text-color: hsl(0, 0%, 100%);

$theme-color-1: hsl(162, 100%, 38%);
$theme-color-2: hsl(42, 98%, 44%);
$theme-color-3: hsl(86, 21%, 50%);
$theme-color-4: hsl(84, 7%, 28%);
$theme-color-5: hsl(43, 37%, 71%);
$theme-color-6: hsl(83, 5%, 48%);

$primary-color: hsl(0, 0%, 13%);
$secondary-color: hsl(0, 0%, 56%);
$muted-color: hsl(0, 0%, 56%);

$gradient-color-1: #00c288;
$gradient-color-2: hsl(162, 100%, 38%);
$gradient-color-3: hsl(161, 100%, 50%);
$gradient-color-4: #00dc99;

$unavailabale-color: #e84271;

$shadowOffsetsTop: 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom: 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

$logoPath: "/assets/img/logo-black.svg";
$logoPathMobile: "/assets/img/logo-mobile.svg";
$adresseIconPath: "/assets/img/ic_gps_fixed_48px.svg";
$calendarIconPath: "/assets/img/Calendar-alt-1.svg";
@import "../_mixins.scss";
@import "../_gogo.style.scss";
@import "../UnAuthLayout.scss";
@import "../utilities.scss";
@import "../AuthLayout.scss";
@import "../pop-up";
@import "../compte-card";
@import "../typography";
@import "../cms";
