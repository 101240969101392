






//agence modal styles
.landingPage-modal {
  &__content {
    position: unset !important;
    max-width: 1140px !important;
    // max-height: auto;
  }
  .modal-dialog {
    position: unset !important;
    max-width: auto !important;
    transform: none !important;
  }
}

.pop-up {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($theme-color-1, 0.8);
  z-index: 9999;
  transition: all 0.3s ease-out;

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 115rem;
    // height: 140rem;
    padding: 20rem 200px;
    background-color: #ffff;
    box-shadow: 0 2rem 4rem rgba(#000, 0.2);
    border-radius: 3rem;
    // overflow: hidden;
    // opacity: 0;
    transform: translate(-50%, -50%) scale(0.25);
    transition: all 0.5s 0.2s;
    text-align: center;
  }

  &__icon--box {
    margin-bottom: 4rem;
  }
  &__icon-laptop {
    font-size: 13rem;
    color: $gradient-color-4;
  }
  &__title {
    color: $theme-color-1;
    font-size: 6rem;
    margin-bottom: 6rem;
    font-family: inherit;
    font-weight: 600;
  }

  &__text {
    p {
      font-size: 3rem;
      line-height: 60px;
      color: gray;
    }
    p:not(:last-of-type) {
      margin-bottom: 8rem;
    }
    p:last-of-type {
      margin-bottom: 5rem;
    }
  }
  &__price {
    font-size: 6rem;
    font-family: inherit;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  &__percentage {
    font-size: 3rem;
    color: gray;
    margin-bottom: 5rem;
  }
  &__cta {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }
  &__btn {
    background-color: $theme-color-1;
    padding: 2rem 0;
    border-radius: 10rem;
    color: #fff;
    font-weight: 600;
    font-size: 4rem;
    font-family: $font-nunito;
  }

  &__close {
    &:link,
    &:visited {
      color: #000;
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;
      font-size: 5rem;
      text-decoration: none;
      display: inline-block;
      transition: all 0.2s;
      line-height: 1;
    }

    &:hover {
      color: $theme-color-1;
    }
  }
}

// calendar-pop-up styles
.app-modal {
  border-radius: 0.75rem;
  .modal-content {
    border-radius: 0.75rem;
  }
  .modal-footer {
    border: none !important;
  }
  &__card {
    position: relative;
    box-shadow: none !important;
    .card-body {
      padding: 2rem;
    }
  }
  &__close {
    // width: 10rem;
    font-size: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  &__header {
    border: none !important;
    margin: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .modal-heading {
      color: $gradient-color-4;
      font-family: $font-nunito;
      font-weight: 700;
      font-size: 2rem;
    }
    img {
      display: block;
      margin-bottom: 1rem;
    }
  }
  &__button-box {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__button {
  }
}
.modal-backdrop {
  background-color: $gradient-color-4;
}
.calendar-reservation-car {
  @media only screen and (min-width: 700px) {
    max-width: 650px !important;
  }
  @media only screen and (min-width: 876px) {
    max-width: 800px !important;
  }
  text-align: center;
  .disponible {
    color: $theme-color-1;
  }
  .indisponible {
    color: rgb(194, 75, 75);
  }
  i {
    font-size: 50px;
  }
  .simple-icon-ban {
    color: rgb(194, 75, 75);
  }
  .simple-icon-check {
    color: rgba($theme-color-1, 0.8);
  }
}
.btn-submit {
  width: 100%;
  background-color: $gradient-color-1;
  color: #fff;
  border: none;
  padding: 0.7rem 0 0.5rem;
  text-transform: uppercase;
}
.btn-confirm {
  background-color: $theme-color-1;
  color: #fff;
  &:hover {
    color: #fff;
  }
}
.btn-cancel {
  color: $theme-color-1;
  border: 1px solid $theme-color-1;
  margin-left: 1rem;

  &:hover {
    background-color: $theme-color-1;
    color: #fff;
  }
}
.reservation-form-card {
  text-align: center;
}

