.header{
  max-width: 100%;
  position: relative;
  
  figure{
    width: 100%;
    position: relative;
    img{
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}
    .overlay--edit{
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(#000,0.5),rgba(#000,0.5));
      .bg-edit-icon{
        width: 6em;
        position: absolute;
        top: 50%;
        left: 50%;
        transform:translate(-50%,-50%);
        cursor: pointer;
        fill: #FFF !important;
        z-index: 5;
        
      }
    }
  }
}
.about{
  // align-items: stretch;
  figure{
    width: 100%;
    height: 400px;
    margin: 0;
    position: relative;
  }
    
  &--image{
    width: 100%;
  }

  .overlay--edit{
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(#000,0.5),rgba(#000,0.5));
    .bg-edit-icon{
      width: 6em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform:translate(-50%,-50%);
      cursor: pointer;
      fill: #FFF !important;
      z-index: 5;
      
    }
  }
  &--description{
    height: 100% !important;
    
}
}

.cropperContainer {
  
  .btn-inline {
    color: #fff !important;
    position: absolute;
    bottom: 1rem;
    z-index: 10000;
    left: 2rem;
    width: 20%;
    border: 1px solid $theme-color-1 ;
  }
  .btn-outline {
    color: $theme-color-1 !important;
    position: absolute;
    bottom: 1rem;
    right: 2rem;
    border: 1px solid $theme-color-1;
    width: 20%;
    padding: 10px 25px !important;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #fff !important;
      background-color: $theme-color-1;
    }
  }
  border-radius: 10px;
  position: fixed ;
  box-shadow: 0 10px 20px rgba($color: #000000, $alpha: 0.4);
  width: 800px;
  height: 500px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  //   top: 30%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
}
.img-container {
  position: absolute;
  z-index: 1000;
  left: 50%;
  // top: 20%;
  transform: translate(-50%, -25%);
  background-color: #fff;
  width: 800px;

  img {
    max-width: 100%;
    height: 100%;
    // margin-bottom: 2rem;
  }
}
