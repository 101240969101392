.mg-bm-large {
  margin: 30px 0 100px;
}
.mg-bm-medium {
  margin: 30px 0 44px;
}
.mg-bm-small {
  margin: 30px 0 18px;
}
.mg-bm-xlg {
  margin: 30px 0 200px;
}
.mt-6 {
  margin-top: 2rem;
}
