// h1,h2,h3,h4,h5,p{
//   display: block;
// }

.top-right-button-container {
  transform: translateY(-12px);
}

.heading-row {
  flex-direction: column;
}
.icon-cards-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  max-width: 100%;
  //   transform: translateX(5px);

  :nth-child(1) {
    grid-column: 1 / span 1;
  }
  :nth-child(2) {
    grid-column: 2 / span 1;
  }
  :nth-child(3) {
    grid-column: 3 / span 1;
  }

  .icon-row-item {
    width: 100%;
  }
  @media only screen and (max-width: 522px) {
    :nth-child(1),
    :nth-child(2),
    :nth-child(3) {
      grid-column: 1 / -1;
    }
  }
}

.cars-card-options {
  .card-text {
    span {
      line-height: 30px;
    }
    height: 48px;
    line-height: 18px;
  }
  img {
    width: 32px;
    height: 32px;
    margin-bottom: 17px;
  }
}

.unavailabale {
  color: #ffff;
  background-color: $unavailabale-color;
}
.available {
  color: #ffff;
  background-color: #02db99;
}

.feature-cards-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  @media only screen and (max-width: 1174px) {
    :nth-child(1),
    :nth-child(3) {
      grid-column: 1 / span 2;
    }
    :nth-child(2),
    :nth-child(4) {
      grid-column: 3 / span 2;
    }
  }

  @media only screen and (max-width: 572px) {
    :nth-child(1),
    :nth-child(3),
    :nth-child(2),
    :nth-child(4) {
      grid-column: 1 / span 4;
    }
  }

  .feature-card {
    color: #000;
    text-transform: capitalize;
    background-color: #ffff;
  }
  :nth-child(4) a .feature-card {
    color: #ffff;
    background-color: #02db99;
  }
  .feature-card-column {
  }
  .card {
    transition: all 0.2s ease;
    img {
      width: 40px;
      height: 40px;
    }

    .card-text {
      line-height: 40px;
    }
  }
  .card:hover {
    box-shadow: 0rem 0.5rem 2rem rgba(#000, 0.15);
  }
}

.car-image-box {
  width: 25%;
  height: auto;
  img {
    object-fit: contain;
    display: block;
    width: 100%;
  }
}
//parc styles
.car-card-icon {
  i {
    font-size: 1.1rem;
    color: #8f8f8f;
    transition: all 0.2s ease;
  }
  :hover {
    color: $theme-color-1;
  }
}
.car-card-parc {
  position: relative;
  height: 100%;
  &__calendar {
    &.calendar-position {
      position: absolute;
      top: 0.3rem;
      right: 3.5rem;
      cursor: pointer;
    }
    i {
      font-size: 25px;
      color: #00dc99;
    }
  }
  .card-body {
    padding: 1rem !important;
  }
  .card-footer {
    border-radius: 0 0 0.75rem 0.75rem;
  }
  &__box {
    max-width: 100%;
    height: 8rem;
    padding: 0 10px;

    .car-switch-dispo {
      top: 0.5rem;
      right: 0.5rem;
    }
  }
  &__picture {
    object-fit: contain;
    display: block;
    max-width: 100%;
    height: 100%;
  }
  // &__footer {
  //   display: flex;
  // }
}
.parc-heading-row {
  width: 100%;
}

.option-parc-hover {
  transition: all 0.2s ease;
  padding-top: 10px;
  padding-bottom: 10px;
  flex: 0 0 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  &:first-child {
    border-bottom-left-radius: 0.75rem;
  }
  &:nth-child(2) {
    border-bottom-right-radius: 0.75rem;
  }
  i {
    font-size: 1.1rem;
    color: #8f8f8f;
    transition: all 0.2s ease;
  }
  &:hover i {
    color: #fff;
  }
}

.add-car-card {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  height: 100%;
  background-color: #f7f7f7;
  cursor: pointer;
  @media screen and (max-width: 576px) {
    height: 258px;
  }
  &__text {
    color: #686868;
    font-family: $font-nunito;
    font-size: 1.2rem;
    font-weight: 600;
  }
  &__icon {
    margin-bottom: 0.5rem;
  }
  .card-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

// thumb list cars style
.thumb-card-car {
  position: relative;
  flex-direction: row !important;
  .custom-switch {
    position: absolute;
    left: -1rem;
    top: 0.3rem;
  }
}

.thumb-card-body {
  position: relative;
  .card-body {
    p {
      margin: 0;
    }

    .car-icon-box {
      .a {
        transition: 0.2s all ease;
        fill: #686868;
      }
      .car-card-icon:hover .a {
        fill: $gradient-color-3;
      }
    }
  }
}

//inserer-vehicule card style
.insert-car-row {
  justify-content: center;
}
.insert-car-column {
  flex: 0 0 1150px !important;
}
.card-addcar {
  padding: 0rem 5rem;
  @media only screen and (max-width: 1024px) {
    padding: 0;
  }
  @media only screen and (max-width: 760px) {
    padding: 0 1rem;
  }
  .card-body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .car-column {
    flex: 0 0 45%;
    max-width: 100%;
    @media only screen and (max-width: 972px) {
      flex: 0 0 282px;
    }
    @media only screen and (max-width: 850px) {
      flex: 0 0 100%;
    }
    @media only screen and (min-width: 1279px) {
      flex: 0 0 400px;
    }
  }
  &__heading {
    @media only screen and (max-width: 847px) {
      font-size: 1.3rem !important;
    }
  }

  &__row {
    width: 100%;
    justify-content: space-between;
  }

  .card-body {
    display: flex;
  }
  .card-title {
    span {
      color: $theme-color-1;
      text-transform: capitalize;
    }
  }

  .option-car-box {
    :not(:last-child) {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .btn__option-car {
      padding: 0.75rem 1.3rem 0.6rem;
      color: $gradient-color-1;
      border: 1px solid $gradient-color-1;
      background-color: #ffff;
      border-radius: 1px;
      &:hover {
        background-color: $gradient-color-1;
        color: #fff;
      }

      &.active {
        background-color: $gradient-color-1;
        color: #fff;
      }
    }
  }
  .btn-submit {
    width: 100%;
    background-color: $gradient-color-1;
    color: #fff;
    border: none;
    padding: 0.7rem 0 0.5rem;
    text-transform: uppercase;
  }

  .car-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    border: none;
    box-shadow: none;
    &__price {
      height: 2.5rem;
      width: 7rem;
      background-color: $theme-color-1;
      color: #fff;
      font-family: $font-nunito;
      font-weight: 700;
      font-size: 1.1rem;
      position: absolute;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-right-radius: 20px;
      right: 1rem;
      top: -1rem;
    }

    &__img-box {
      width: 100%;
      img {
        height: auto;
        object-fit: contain;
        width: 100%;
      }
    }
    &__name {
    }
    &__details {
      position: relative;
      padding: 40px;
      @media only screen and (max-width: 972px) {
        padding: 20px;
      }
      @media only screen and (max-width: 780px) {
        padding: 40px;
      }
      @media only screen and (max-width: 438px) {
        padding: 20px;
        font-size: 0.7rem;
      }
      background-color: #ebebeb;
      .car-title {
        font-size: 1.6rem;
        color: #000;
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }
}

//agence-style
.agence-card {
  position: relative;
  .btn-edit {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    text-transform: capitalize;
  }
  .card-body {
    padding: 1rem;
  }

  &__car {
    margin-bottom: 2rem;
    .card {
      height: 100%;
    }
  }

  &__calender {
    margin-bottom: 1rem;
  }
  &__days-of-work {
    span {
      display: inline-block;
      padding: 0.2rem 0.5rem;
      background-color: #02db99;
      color: #ffff;
      margin-right: 0.2rem;
      border: 1px solid #02db99;
      border-radius: 5px;
      margin-bottom: 0.5rem;
    }
  }
  &__icon {
    margin-right: 0.5rem;
  }
  &__adresse {
  }
  &__contact-list {
    list-style: none;
    .contact-item {
      font-family: $font-nunito;
      font-size: 0.7rem;
      font-weight: 400;
      margin-bottom: 0.2rem;
    }
  }
  &__logo-box {
    height: 90%;
    .logo {
    }
  }
}

//pricing-table style
.pricing-row {
  // .active_offer {
  //   .pricing-card {
  //     background-image: linear-gradient((228deg, #02db99 0%, #03cbcb 100%));
  //     &__percentage,
  //     &__price,
  //     &__offre-type {
  //       color: #fff;
  //     }
  //     .cta-btn {
  //       background-color: #fff;
  //       color: #00dc99;
  //       &:hover {
  //         border-color: #fff !important;
  //         background-color: #fff !important;
  //       }
  //     }
  //   }
  // }
  .padd-2 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .pricing-card {
    .price,
    .offre-type,
    .cta-btn {
      font-family: $font-nunito;
      font-weight: 600;
    }
    .logo-box {
      width: 100%;
      // margin-bottom: 3rem;
    }

    .icon-tag {
      color: #000 !important;
    }
    .icon-phone {
      color: #fff !important;
    }
    .icon {
      font-size: 2.5rem;
      font-weight: 300;
      color: $gradient-color-4;
    }

    .price {
      color: #000;
      font-size: 1.8rem;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .offre-type {
      color: #00dc99;
      text-transform: capitalize;
      font-size: 1rem;
    }
    .cta-btn {
      font-size: 0.8rem !important;
    }
  }

  .inactive_offer {
    .price,
    .offre-type,
    .cta-btn {
      font-family: $font-nunito;
      font-weight: 600;
    }
    .logo-box {
      width: 100%;
      // margin-bottom: 3rem;
    }

    .icon-tag {
      color: #000 !important;
    }
    .icon-phone {
      color: #fff !important;
    }
    .icon {
      font-size: 2.5rem;
      font-weight: 300;
      color: $gradient-color-4;
    }

    .price {
      color: #000;
      font-size: 1.8rem;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .offre-type {
      color: #00dc99;
      text-transform: capitalize;
      font-size: 1rem;
    }
    .cta-btn {
      font-size: 0.8rem !important;
    }
  }

  .active_offer {
    background-image: linear-gradient((228deg, #02db99 0%, #03cbcb 100%));
    .percentage {
      color: #fff;
    }
    .price,
    .offre-type {
      color: #fff;
      font-family: $font-nunito;
      font-weight: 600;
    }
    .cta-btn {
      background-color: #fff;
      color: #00dc99;
      font-family: $font-nunito;
      font-weight: 600;
      font-size: 0.8rem !important;
      &:hover {
        border-color: #fff !important;
        background-color: #fff !important;
      }
    }

    .logo-box {
      width: 100%;
    }

    .icon-tag {
      color: #000 !important;
    }
    .icon-phone {
      color: #fff !important;
    }
    .icon {
      font-size: 2.5rem;
      font-weight: 300;
      color: #fff;
    }

    .price {
      font-size: 1.8rem;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .offre-type {
      text-transform: capitalize;
      font-size: 1rem;
    }
  }

  .pending_offer {
    background-image: linear-gradient((228deg, #ffca4d 0%, #e8831b 100%));
    .percentage {
      color: #fff;
    }
    .price,
    .offre-type {
      color: #fff;
      font-family: $font-nunito;
      font-weight: 600;
    }
    .cta-btn {
      background-color: #fff;
      color: #dba102;
      font-family: $font-nunito;
      font-weight: 600;
      font-size: 0.8rem !important;
      &:hover {
        border-color: #dba102 !important;
        background-color: #fff !important;
      }
    }

    .logo-box {
      width: 100%;
    }

    .icon-tag {
      color: #000 !important;
    }
    .icon-phone {
      color: #fff !important;
    }
    .icon {
      font-size: 2.5rem;
      font-weight: 300;
      color: #fff;
    }

    .price {
      font-size: 1.8rem;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .offre-type {
      text-transform: capitalize;
      font-size: 1rem;
    }
  }
}

//template page style

.template-card {
  position: relative;
  width: 100%;

  &__overlay {
    visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(#000, 0.7), rgba(#000, 0.7));
    opacity: 0;
    top: 0;
    transition: opacity 0.2s ease;
  }
  &:hover &__overlay {
    visibility: visible;
    display: block;
    opacity: 0.7;
  }
  &:hover &__btn {
    display: block;
  }

  &__img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  &__btn {
    position: absolute;
    transform: translateY(-50%);
    transition: 0.2s all ease;
    z-index: 100;
    display: none;
    top: 50%;
    width: 180px;
  }
  &__left {
    left: 10%;
  }
  &__right {
    right: 10%;
  }
}

// calendar Page styles
.calendar-card {
  height: auto;
  margin-bottom: 1rem;
  border-radius: 5px !important;
  .card-body {
    height: auto;
  }
  // .react-datepicker__week {
  //   height: 100px !important;
  // }
  // .react-datepicker__day {
  //   border: 1px solid #eaf0f4;
  //   height: 100px !important;
  //   text-align: right;
  //   padding-right: 1rem;
  // }
  // .react-datepicker__day-name {
  //   text-align: center;
  //   // display: flex;
  //   // flex-direction: column;
  //   // align-items: center;
  //   // justify-content: center;
  //   color: #fff !important;
  //   height: 100% !important;
  //   font-size: 1rem;
  // }
  // .react-datepicker__day-names {
  //   background-color: #00dc99;
  //   height: 50px !important;
  // }
  // .react-datepicker__navigation {
  //   border: 0.6rem solid transparent !important;
  // }
  // .react-datepicker__navigation {
  //   &--previous {
  //     right: 40px !important;
  //     border-right-color: #00dc99 !important;
  //   }
  // }
  // .react-datepicker__navigation {
  //   &--next {
  //     right: 10px !important;
  //     border-left-color: #00dc99 !important;
  //   }
  // }
  // .react-datepicker__current-month {
  //   color: #00dc99 !important;
  //   margin-bottom: 1rem;
  //   text-align: left;
  //   font-size: 1.5rem;
  // }

  // .react-datepicker__month {
  //   margin: 0;
  // }
}

@media only screen and (min-width: 1200px) {
  .card-addcar {
    .card-body {
      display: flex;
      justify-content: space-between;
    }
    // .car-column {
    //   width: 500px;
    // }
  }
}

//calendar-styles
.calendar-row {
  margin-left: 0px;
  margin-right: 0px;
}

//chat app styles
.icon-plus {
  width: 18px;
  height: 25px;
}
.parametres-menu {
  width: 100%;
  height: 100%;

  .icon-plus-circle {
    position: absolute;
    bottom: 1.5rem;
    right: 0.5rem;
    z-index: 100;
  }
}
.message-box {
  // text-align: end;
  border: 1px solid #00dc99;
  width: 100%;
  display: flex;
  &:hover {
    background-color: #00dc99;
  }
  &:hover .message-text {
    color: #fff;
  }
  &:hover .delete-box-icon .a {
    fill: #fff;
  }
  .message-text {
    flex: 0 0 80%;
    font-size: 0.8rem;
    font-family: $font-nunito;
    font-weight: 600;
  }

  .delete-box-icon {
    flex: 0 0 20%;
    display: flex;
    justify-content: center;

    .a {
      font-size: 2rem;
      width: 2rem;
      height: 2rem;
      fill: #686868;
    }
    cursor: pointer;
  }
  .delete-icon {
    width: 1rem;
    height: 1rem;
    color: #00dc99;
  }
}

.message-modal {
  &__card {
    position: relative;
    box-shadow: none !important;
    .card-body {
      padding: 2rem;
    }
  }
  &__header-box {
    border: none !important;
    margin: 1rem 2rem;
    text-align: center;
  }
  &__heading {
    color: $gradient-color-4;
    font-family: $font-nunito;
    font-weight: 700;
    font-size: 2rem;
  }
  &__button-box {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.select-contact-wrapper {
  position: relative;
  height: 60vh;
  width: calc(100% - 280px);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
}
.select-contact {
  &__icon {
    width: 330px;
    margin-bottom: 1.5rem;
  }
  &__text {
    color: #686868;
    font-family: $font-nunito;
    font-weight: 600;
    font-size: 2rem;
  }
}
// .hidden{
//   display: block;
// }
@media only screen and (max-width: 767px) {
  .main-menu {
    display: none;
  }
}

@media only screen and (max-width: 490px) {
  .thumb-card-body {
    .custom-switch {
      position: relative;
      transition: all 0.1s ease-out;
      transform: translate(1rem, -1rem);
    }
  }
}

.rbc-event.rbc-selected {
  background-color: $theme-color-1;
}
