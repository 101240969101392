.agenceInfo-card {
  position: relative;

  .card-body {
    display: flex;
    flex-direction: column;
  }

  // width: 20rem;
  &__heading {
  }
  &__logo-box {
    position: relative;
    width: 100%;
    background-color: #f9f9f9;
    .logo {
      height: auto;
      max-width: 100%;
      object-fit: contain;
      display: block;
    }
  }
  .icon-box {
    position: absolute;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    bottom: 0.5rem;
    right: 0.5rem;
    background-color: $gradient-color-1;
    display: flex;
    justify-content: center;
    cursor: pointer;
    .logo-edit-icon {
      width: 75%;
      height: auto;
    }
  }
  &__info {
  }
}

.abonnement-card {
  .card-body {
    display: flex;
    flex-direction: column;
  }
  &__heading {
  }
  &___info {
  }
  .badge:nth-of-type(1) {
    margin-bottom: 0.5rem;
  }
  .abonnement {
    margin-bottom: 0.5rem;
  }
}

.compte-card {
  .card-body {
    display: flex;
    flex-direction: column;
  }
}
