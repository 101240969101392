.heading-secondary {
  font-family: $font-nunito;
  font-weight: 600;
  font-size: 1.5rem;
}
.heading-third {
  font-family: $font-nunito;
  font-weight: 200;
  font-size: 1.1rem;
}
.heading-main {
  font-family: $font-nunito;
  font-weight: 700;
  font-size: 1.5rem;
}
.heading-fourth{
  font-family: $font-nunito;
  font-weight: 600;
  font-size: 1.1rem;
}
.heading-fifth{
  font-family: $font-nunito;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
}
.sub-heading{
  font-family: $font-nunito;
  font-weight: 300 !important;
  color:#000 !important;
  font-size: 0.7rem;
}
.sub-heading-2{
  font-family: $font-nunito;
  font-weight: 600;
  color:#686868 !important;
  font-size: 1.1rem;
}
